import * as React from 'react';
import Seo from '../components/seo';
import FeatureSectionRight from '../components/FeatureSectionRight/FeatureSectionRight';
import Layout from '../components/layout';

const JetsAboutPage = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <FeatureSectionRight
          imageSrc="/jets/jets-founder.png"
          imageAlt="Image 1"
          title="代表挨拶"
          description={`当事務所は、社会保険労務士、行政書士、CFP（ファイナンシャルプランナー）という３つの資格を組み合わせたサービスを展開しています。
        社会保険労務士としては、各種雇用関係助成金の申請サポート、労務・人事関係全般の手続きや働き方改革への対応、シニア人材活用や求人、人事評価のコンサルティングなど幅広く対応します。
        行政書士としては、各種補助金の申請サポート、外国人ビザ申請取次、各種許認可申請（建設業、運輸業、宅建業、古物商、放課後等デイサービスなど）、農地転用手続き、会社設立支援（定款作成など）等、法務・行政手続き全般をサポートします。 
        「人生100年時代」と言われる中、ファイナンシャルプランナーとしての知識も業務に生かしているところです。
        私は大学卒業後、北九州市役所で26年間勤務した後、独立開業いたしました。その間、通商産業省（現：経済産業省）への出向も経験するなど、国・地方双方の政策立案に関わることができました。
        公務員を辞めることが極めて少ないため、「なぜ辞めたのか？」と聞かれることが多々あります。でも、それを裏返して考えると、だから政策の意図が伝わらず、うまく活用できないケースが少なくないと言えます。
        私は、自分のこれまでの経験を中小企業の経営者様にご提供しながら、ともに成長していけるパートナーとなることを目指して、各種業務のご相談を承っています。        
      
        ＜保有資格＞
        〇社会保険労務士
        〇行政書士
        〇ＣＦＰ（日本ＦＰ協会）
        〇宅地建物取引士
        〇ＤＣプランナー１級
        〇日商簿記２級
        〇福祉住環境コーディネーター２級
        `}
          founderName="代表 岩田 健"
        />
      </Layout>
    </div>
  );
};

export default JetsAboutPage;

export const Head = () => (
  <Seo title="JETS社労士事務所" description="会社概要をご紹介します。" />
);
